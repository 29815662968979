import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c(VRow,[_c(VCol,{attrs:{"cols":"6","offset":"3"}},[_c(VCard,{staticStyle:{"margin":"10px"}},[_c(VCardText,[_c(VFileInput,{attrs:{"show-size":"","label":"Загрузите начальный бюджет проекта"},on:{"change":_vm.uploadFile}})],1),_c(VCardText,[_vm._v(" Работает только для версии v6.1 коммерческой таблицы! ")])],1)],1),_c(VCol,{attrs:{"cols":"6","offset":"3"}},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pfbudgets,"items-per-page":100},scopedSlots:_vm._u([{key:`item.pfbudgetinitfile`,fn:function({ item }){return [_c('a',{attrs:{"href":item.pfbudgetinitfile.docfile}},[_vm._v(_vm._s(item.project_title))])]}},{key:`item.pfbudgetpffile`,fn:function({ item }){return [_c('a',{attrs:{"href":item.pfbudgetpffile.docfile}},[_vm._v(_vm._s(item.project_title)+"_pf")])]}},{key:`item.who_uploaded_file`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.who_uploaded_file.first_name)+" "+_vm._s(item.who_uploaded_file.last_name)+" ")]}}],null,true)})],1)],1)],_c(VFooter,{attrs:{"color":"red","padless":""}},[_c(VCard,{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"py-2 text-center"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("powered by GIP GROUP")]),_vm._v(" "),_c('br'),_c('a',{attrs:{"href":"https://gip-group.su"}},[_c('strong',[_vm._v("https://gip-group.su")])])])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }