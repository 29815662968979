<template>
	<div>
        <template>
            <v-row>
				<v-col cols="6" offset="3">
					<v-card style="margin: 10px">
						<v-card-text>
							<v-file-input
								show-size
								label="Загрузите начальный бюджет проекта"
								@change="uploadFile"
							></v-file-input>
						</v-card-text>
						<v-card-text>
							Работает только для версии v6.1 коммерческой таблицы!
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6" offset="3">
					<v-data-table
						:headers="headers"
						:items="pfbudgets"
						:items-per-page="100"
						class="elevation-1"
					>
					<template v-slot:[`item.pfbudgetinitfile`]="{ item }">
						<a v-bind:href="item.pfbudgetinitfile.docfile">{{item.project_title}}</a>
					</template>
					<template v-slot:[`item.pfbudgetpffile`]="{ item }">
						<a v-bind:href="item.pfbudgetpffile.docfile">{{item.project_title}}_pf</a>
					</template>
					<template v-slot:[`item.who_uploaded_file`]="{ item }">
						{{item.who_uploaded_file.first_name}} {{item.who_uploaded_file.last_name}}
					</template>
					</v-data-table>
				</v-col>

            </v-row>
        </template>
		<v-footer color="red" padless>
			<v-card class="flex" flat tile>
				<v-card-text class="py-2 text-center">
					{{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
					<a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
				</v-card-text>
			</v-card>
		</v-footer>
	</div>
</template>

<script>


export default {
	computed: {
		user() {
			return this.$store.state.authentication.user;
		},
		users() {
			return this.$store.state.users.all;
		},
	},

	data: () => ({
		user_data: {},
		headers: [
          {
            text: 'id',
            value: 'id',
          },
          { text: 'Начальный бюджет', value: 'pfbudgetinitfile' },
          { text: 'Бюджет для импорта в pf', value: 'pfbudgetpffile' },
          { text: 'Кто создал', value: 'who_uploaded_file' },
        ],
		pfbudgets: [],
	}),
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},

	created() {
		this.initialize()
	},

	methods: {
		initialize() {

			let user = JSON.parse(localStorage.getItem('user'));
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			let self = this
			this.$axios
				.get(process.env.VUE_APP_BACKENDAPI + 'v1/planfact/pfbudgets')
				.then(function (response) {
					self.pfbudgets = response.data
				});

			let _user = JSON.parse(localStorage.getItem('user'));

			if (_user) {
				let self = this;
				this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
					.then(function (response) {
						localStorage.setItem('user_data', JSON.stringify(response.data));
						self.user_data = JSON.parse(localStorage.getItem('user_data'))
					});
			}


		},

		uploadFile(uploaded_file) {
			// создание объекта
			let user = JSON.parse(localStorage.getItem('user'));
			let self = this;
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			this.$axios.defaults.headers.common['Content-Type'] = 'Bearer ' + user.access;

			const formData = new FormData();
      		formData.append("docfile", uploaded_file);
			if (uploaded_file) {
				this.$axios.post(
				process.env.VUE_APP_BACKENDAPI + 'v1/planfact/pfbudgets/upload-init-file',
				formData,
				).then(function (response) {
					
					let load_data = {
							'pfbudgetinitfile': response.data['init_budget_file_id'],
							'pfbudgetpffile': response.data['pf_budget_pf_file_id'],
							'who_uploaded_file': self.user_data.id,
						}
					console.log(load_data)
					// СОЗДАДИМ БЮДЖЕТ
					self.$axios.post(
						process.env.VUE_APP_BACKENDAPI + 'v1/planfact/pfbudgets',
							load_data
						).then(function (response) {
							self.initialize()
							self.$notify({
								title: 'Успешно',
								text: 'Контакт успешно создан',
								type: 'success',
								duration: 5000,
							})
						})
				})
					.catch((error) => {
					if (error.response) {
						self.$notify({
							title: 'Ошибка',
							text: error.response.data,
							type: 'error',
							duration: 5000,
						});
					}
				});}
			}


	},
}
</script>

