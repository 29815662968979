<template>
    <v-app>
        <div>
            <v-toolbar blue>
                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                <v-toolbar-title>
                    <router-link :to="{ name: 'planfact' }">Convert budget file to planfact import</router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                    <v-toolbar-title v-if="user_data">{{ user_data.first_name }} {{ user_data.last_name }}</v-toolbar-title>
                    <v-btn icon v-if="user_data">
                        <router-link style="text-decoration: none; color: inherit;" to="/login">
                            <v-icon>mdi-export</v-icon>
                        </router-link>
                    </v-btn>

            </v-toolbar>
        </div>
        <div class="">
            <div class="">
                <div class="">
                    <div v-if="alert.message" :class="`alert ${alert.type}`">{{ alert.message }}</div>
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <notifications  position="bottom right"/>
    </v-app>
</template>

<script>
export default {
    name: 'app',
    data() {
        return {
            user_data: {}
        }
    },
    computed: {
        alert() {
            return this.$store.state.alert
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            let _user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            let user_data = JSON.parse(localStorage.getItem('user_data'))
            self.user_data = user_data

            if (_user) {
                this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
                    .then(function (response) {
                        localStorage.setItem('user_data', JSON.stringify(response.data));
                        self.user_data = JSON.parse(localStorage.getItem('user_data'))
                    });
            }

        }
    },

    watch: {
        $route(to, from) {
            // clear alert on location change
            this.$store.dispatch('alert/clear');
        }
    }
};
</script>