import Vue from 'vue';
import Router from 'vue-router';

import UploadFile from '../business/UploadFile.vue'
import LoginPage from '../login/LoginPage.vue'


Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      component: UploadFile,
      name: 'planfact',
    },
    {
      path: '/login',
      component: LoginPage
    },
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})